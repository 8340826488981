
.homepage{
	article{
		h1{
			font-size: 1.5rem;
			margin-bottom: 3rem;
		}
	}
}
.v-mid{
	text-align: center;
	@media #{$medium-up} {
		margin: 6rem 0 0 0;
		text-align: left;
	}
}

img.large-full{
	width: 100%;
	@media #{$medium-up} {
		width: auto;
	}
}

.cta{
	padding: 2rem 0 5rem 0;
	.button{
	    background: #000;
	    border-radius: 0;
		font-size: 1.5rem;
		margin: 0;
		padding: 1.5rem 3rem;
	    text-transform: uppercase;
	}
	.sub{
		color: rgba(0,0,0, .6);
	}
}
