.comp-admin-menu{
	margin: 2rem 0;
	ul{
		li{
			a{
				border-right: 1px solid $light-gray;
				margin-right: 1rem;
				padding: 0 1rem 0 0;
			}
			&:last-child{
				a{
					border: 0;
				}
			}
		}
	}	
}