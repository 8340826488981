.offcanvas-toggle{
	color: $white;
	float: right;
	font-size: 1.5rem;
	padding: 1rem .5rem;
}

.off-canvas{
	height: 100%;
	h4{
		margin: 1rem 0 .5rem 1rem;
	    color: #ccc;
	    font-size: .75rem;
	}
	.menu{
		li{
			border-bottom: 1px solid #ddd;
		}
		a{
			color: #000;
			padding: 1rem;
			&:hover,
			&:focus{
				background: #000;
				color: $white;
			}
		}
		.submenu{
			margin: 0;
			background: rgba(0,0,0,.1);
			li{
				a{
					font-size: .875rem;
				}
			}
		}
	}
}