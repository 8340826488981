.score{
	tr{
		&:last-child{
			input{
				width: 170px;
			}
		}
	}
	td{
		&:first-child{
			width: 200px;
		}
		input{
			display: inline-block;
			margin-bottom: 0;
			margin-right: .5rem;
			width: 100px;
		}
	}
}

.judging-management{
	table{
		tr{
			td{
				&:first-child{
					@media #{$medium-up} {
						width: 500px;
					}
					@media #{$large-up} {
						width: 750px;
					}
				}
			}
		}
	}
}

.hover{
	thead{
		tr{
			&:hover{
				background: $medium-gray;
			}
		}
	}
}

.table-responsive{
	overflow: auto;
}