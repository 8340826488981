.comp-global-header{
	.row{
		> .columns{
			position: relative;
			.site-logo{
				position: absolute;
			    left: .9375rem;
			    margin: 0;
			    padding: 0;
			    line-height: 0;
			    top: .5rem;
			    img{
			    	width: 90px;
			    }
			}
		}
	}
	.dropdown{
		float: left;
		margin: 0 0 0 8rem;
		text-transform: uppercase;
		a{
			color: $white;
		}
		li{
			&:first-child{
				a{
					border: 0;
					padding: 1.5rem 1rem;
				}
			}
			.submenu{
				border: 0;
				-moz-box-shadow:    0px 0px 5px 0px rgba(0,0,0,.2);
				-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,.2);
				box-shadow:         0px 0px 5px 0px rgba(0,0,0,.2);
				li{
					a{
						color: #000;
						font-size: .875rem;
						padding: 1rem;
						&:hover{
							background: #000;
							color: $white;
						}
					}
				}
			}
		}
	}
	.login{
		color: $white;
		float: right;
		line-height: 1;
		padding: 1.5rem 1rem;
		text-transform: uppercase;
	}
}