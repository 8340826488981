$yellow: #f6df44;

.yellow{
	background: $yellow;
}

.black{
	background: #000;
	color: $white;
}


article{
	padding: 2rem 0;
}


section{
	position: relative;
}

.captcha-img{
	margin: 1rem 0;
}

.refer-categories{
	display: block;
	margin-top: 2rem;
}

.entry-categories{
	h1{
		padding-right: 2.5rem;
	}
	ol{
		margin-top: 2rem;
		li{
			h6{
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}

.campaign-summary,
.support-material,
.winners-press{
	h2{
		font-weight: bold;
	}
}

.reveal{
	width: 100%;
	@media #{$large-up} {
		width: 1000px;
	}
}


.caps{
	text-transform: uppercase;
}


.section-menu{
	margin: 1rem 0;
}


.contact{
	margin-top: 2rem;
}

.payment{
	margin-bottom: 3rem;
}

.network-name{
	display: none;
}

button[type="reset"]{
	margin-left: .25rem;
}

.captcha{
	margin-bottom: 1rem;
}

.captcha.is-invalid-label{
	display: none;
}

.block{
	display: block;
}

.main{
	min-height: 38rem;
	@media #{$medium-up} {
		min-height: auto;
	}
}
